import React from 'react';
import { useSelector } from 'react-redux';

const Loader = () => {
  const isHideLogin = useSelector(state => state.app.isHideLogin);
  if (!isHideLogin) return null;
  return <div className="utils__loadingPage" />;
}

export default Loader;
