import React, {useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {selectNetworkError, selectPathName, setUpdatingContent} from '../../../ducks/app'
import Breadcrumb from '../Breadcrumb'
import {Button} from 'antd';
import {Outlet} from "react-router-dom";

const AppContent = (props) => {

  const dispatch = useDispatch();
  const pathName = useSelector(selectPathName);
  //const isUpdatingContent = useSelector(state => state.app.isUpdatingContent);

  // useEffect(() => {
  //   if (isUpdatingContent) {
  //     dispatch(setUpdatingContent(false));
  //   }
  // }, [isUpdatingContent]);

  const { refreshNeeded } = useSelector(state => state.app);
  const networkError = useSelector(selectNetworkError);
  return refreshNeeded ? (
    <div className="utils__content">
      <h5>Updates to MyNjAppeal are now available. Click the button to continue.</h5>
      <span>
        <Button
          onClick={() => {
            window.location.reload();
          }}
          // icon="check"
          size="default"
          type="primary"
          style={{marginTop:"10px"}}
        >
          Refresh Page
        </Button>
      </span>
    </div>
  ) : (
    <div className="utils__content">
      <Breadcrumb name={pathName} />
      {networkError &&
        <div style={{color:"red",fontSize:"2em",fontWeight:"bold"}}>A network error has occurred. Please close the browser and try again.</div>
      }
      <div className="outlet_wrapper">
        <Outlet />
      </div>
    </div>
  );
}

export default AppContent;
