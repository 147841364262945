import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { setLayoutState, selectLayoutState } from '../../../ducks/app'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { Switch } from 'antd'
import './style.scss'


const SettingsSider = () => {
  const layoutState = useSelector(selectLayoutState);
  const dispatch = useDispatch();


  const closeSettings = () => {
    dispatch(setLayoutState({ settingsOpened: false }))
  }

  // setMenuCollapsed = state => {
  //   dispatch(setLayoutState({ menuCollapsed: state }))
  // }

  const setMenuShadow = state => {
    dispatch(setLayoutState({ menuShadow: state }))
  }

  const setThemeLight = state => {
    dispatch(setLayoutState({ themeLight: state }))
  }

  const setSquaredBorders = state => {
    dispatch(setLayoutState({ squaredBorders: state }))
  }

  const setFixedWidth = state => {
    dispatch(setLayoutState({ fixedWidth: state }))
  }

  const setBorderLess = state => {
    dispatch(setLayoutState({ borderLess: state }))
  }

  const setIsMenuTop = state => {
    dispatch(setLayoutState({ isMenuTop: state }))
  }

  return (
    <div
      className={'settingsSider ' + (layoutState.settingsOpened ? 'settingsSider--opened' : '')}
    >
      <Scrollbars style={{ height: '100vh' }}>
        <div className="settingsSider__container">
          <div className="settingsSider__title">
            Theme Settings
            <span className="fa fa-times" onClick={closeSettings} />
          </div>
          <div className="settingsSider__description">
            This component "SettingsSider" gives possibility to construct custom blocks with any
            widgets, components and elements inside, like this theme settings
          </div>
          <div className="settingsSider__item">
            <Switch checked={layoutState.isMenuTop} onChange={setIsMenuTop} />
            <span className="settingsSider__itemLabel">Menu Top (Horizontal)</span>
          </div>
          {/*<div className="settingsSider__item">
            <Switch checked={layoutState.menuCollapsed} onChange={setMenuCollapsed} />
            <span className="settingsSider__itemLabel">Collapsed Menu</span>
          </div>*/}
          <div className="settingsSider__item">
            <Switch checked={layoutState.menuShadow} onChange={setMenuShadow} />
            <span className="settingsSider__itemLabel">Menu Shadow</span>
          </div>
          <div className="settingsSider__item">
            <Switch checked={layoutState.themeLight} onChange={setThemeLight} />
            <span className="settingsSider__itemLabel">Light Theme</span>
          </div>
          <div className="settingsSider__item">
            <Switch checked={layoutState.borderLess} onChange={setBorderLess} />
            <span className="settingsSider__itemLabel">Borderless Cards</span>
          </div>
          <div className="settingsSider__item">
            <Switch checked={layoutState.squaredBorders} onChange={setSquaredBorders} />
            <span className="settingsSider__itemLabel">Squared Borders</span>
          </div>
          <div className="settingsSider__item">
            <Switch checked={layoutState.fixedWidth} onChange={setFixedWidth} />
            <span className="settingsSider__itemLabel">Fixed Width</span>
          </div>
        </div>
      </Scrollbars>
    </div>
  );
}

export default SettingsSider;
