import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Menu } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import { setLayoutState } from '../../../../ducks/app'
import { default as menuData } from './menuData'
import ProfileMenu from '../MenuRight'
import './style.scss'

const SubMenu = Menu.SubMenu;
const Divider = Menu.Divider;

const MenuTop = ({ isMobile }) => {
  const dispatch = useDispatch();
  const [selectedKeys, setSelectedKeys] = useState('');
  const [openKeys, setOpenKeys] = useState(['']);
  const pathname = useLocation().pathname;
  const collapsed = useSelector(state => state.app.layoutState.menuCollapsed);
  const theme = useSelector(state => state.app.layoutState.themeLight) ? 'light' : 'dark';
  const settingsOpened = useSelector(state => state.app.layoutState.settingsOpened);
  useEffect(() => {
    getActiveMenuItem(menuData);
  }, [isMobile])

  const handleClick = e => {
    if (isMobile) {
      // collapse menu on isMobile state
      dispatch(setLayoutState({ menuMobileOpened: false }))
    }
    if (e.key === 'settings') {
      // prevent click and toggle settings block on theme settings link
      dispatch(setLayoutState({ settingsOpened: !settingsOpened }))
      return
    }
    // set current selected keys
    setSelectedKeys(e.key);
    setOpenKeys(e.keyPath);
  };

  const onOpenChange = openKeys => {
    setOpenKeys(openKeys);
  };

  const getPath = (data, id, parents = []) => {
    let items = data.reduce(
      (result, entry) => {
        if (result.length) {
          return result;
        } else if (entry.url === id && selectedKeys === '') {
          return [entry].concat(parents);
        } else if (entry.key === id && selectedKeys !== '') {
          return [entry].concat(parents);
        } else if (entry.children) {
          let nested = getPath(entry.children, id, [entry].concat(parents));
          return nested.length ? nested : result;
        }
        return result;
      },
      [],
    )
    return items;
  }

  const getActiveMenuItem = (items) => {
    let [activeMenuItem] = getPath(items, !selectedKeys ? pathname : selectedKeys)
    setSelectedKeys (activeMenuItem ? activeMenuItem.key : '');
  }

  const generateMenuPartitions = (items) => {
    return items.map(menuItem => {
      if (menuItem.children) {
        let subMenuTitle = (
          <span className="menuTop__title-wrap" key={menuItem.key}>
            <span className="menuTop__item-title">{menuItem.title}</span>
            {menuItem.icon && <span className={menuItem.icon + ' menuTop__icon'} />}
          </span>
        )
        return (
          <SubMenu title={subMenuTitle} key={menuItem.key}>
            {generateMenuPartitions(menuItem.children)}
          </SubMenu>
        )
      }
      return generateMenuItem(menuItem)
    })
  }

  const generateMenuItem = (item) => {
    const { key, title, url, icon, disabled } = item
    return item.divider ? (
      <Divider key={Math.random()} />
    ) : item.url ? (
      <Menu.Item key={key} disabled={disabled}>
        <Link
          to={url}
          onClick={
            isMobile
              ? () => {
                  dispatch(setLayoutState({ menuCollapsed: false }))
                }
              : undefined
          }
        >
          <span className="menuTop__item-title">{title}</span>
          {icon && <span className={icon + ' menuTop__icon'} />}
        </Link>
      </Menu.Item>
    ) : (
      <Menu.Item key={key} disabled={disabled}>
        <span className="menuTop__item-title">{title}</span>
        {icon && <span className={icon + ' menuTop__icon'} />}
      </Menu.Item>
    )
  }

  const menuItems = generateMenuPartitions(menuData);
  return (
    <div className="menuTop">
      <div className="menuTop__logo">
        <div className="menuTop__logoContainer">
          <img src="resources/images/logo-inverse.png" alt="" />
        </div>
      </div>
      <Menu
        theme={theme}
        onClick={handleClick}
        selectedKeys={[selectedKeys]}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        mode="horizontal"
        className="menuTop__navigation"
      >
        <Menu.Item key={'settings'}>
          <span className="menuTop__item-title">Settings</span>
          <span className={'icmn icmn-cog menuTop__icon utils__spin-delayed--pseudo-selector'} />
        </Menu.Item>
        {menuItems}
      </Menu>
      <ProfileMenu />
    </div>
  );
}
export default MenuTop;
export { menuData };
