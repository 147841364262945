import React, { Suspense } from 'react'
import {Routes, Route} from "react-router-dom";
import {FullLayout} from "../components/LayoutComponents/FullLayout";

const Loading = () => {
  return (
    <div>Loading...</div>
  );
}

export const ROUTE_AO_APPEAL_MANAGEMENT = '/searchAOAppeals';
export const ROUTE_APPEAL_MANAGEMENT = '/searchAppeals';
export const ROUTE_FILE_ADD_OMIT_APPEAL = '/fileAnAddedOmittedAppeal';
export const ROUTE_FILE_VALUE_APPEAL = '/fileAnAppeal';

export const getDefaultPath = () => ROUTE_FILE_VALUE_APPEAL;
//export const getDefaultPath = () => ROUTE_FILE_ADD_OMIT_APPEAL;

const UserRoutes = () => {
  const FileAnAppealPage = React.lazy(() => import("../pages/DefaultPages/FileAnAppealPage"));
  const FileAnAdditionAppealPage = React.lazy(() => import("../pages/DefaultPages/FileAnAdditionAppealPage"));
  const FileADeductionAppealPage = React.lazy(() => import("../pages/DefaultPages/FileADeductionAppealPage"));
  const FileBatchValueAppealsPage = React.lazy(() => import("../pages/DefaultPages/FileBatchValuationAppealsPage"));
  const SearchAppealsPage = React.lazy(() => import("../pages/DefaultPages/SearchAppealsPage"));
  const SearchTenAppealsPage = React.lazy(() => import("../pages/DefaultPages/SearchTenAppealsPage"));
  const CreateHearingSchedulePage = React.lazy(() => import("../pages/DefaultPages/CreateHearingSchedulePage"));
  const ReportsPage = React.lazy(() => import("../pages/DefaultPages/Analytics/ReportsPage"));
  const BulkJudgmentsPage = React.lazy(() => import("../pages/DefaultPages/BulkJudgmentsPage"));
  const AccountInfoPage = React.lazy(() => import("../pages/DefaultPages/AccountInfoPage"));
  const AppealDetailPage = React.lazy(() => import("../pages/DefaultPages/AppealDetailPage"));
  return (
    <Routes>
      <Route path='/' element={<FullLayout />}>
        {/*<Route index element={<Suspense fallback={<Loading />}><FileAnAppealPage /></Suspense>} />*/}
        {/*<Route path='/fileAnAppeal' element={<FileAnAppealPage />} />*/}
        <Route index element={<Suspense fallback={<Loading />}><FileAnAppealPage /></Suspense>} />
        <Route path={ROUTE_FILE_VALUE_APPEAL} element={<Suspense fallback={<Loading />}><FileAnAppealPage /></Suspense>} />
        <Route path='/fileBatchValueAppeals' element={<Suspense fallback={<Loading />}><FileBatchValueAppealsPage /></Suspense>} />
        <Route path={ROUTE_FILE_ADD_OMIT_APPEAL} element={<Suspense fallback={<Loading />}><FileAnAdditionAppealPage /></Suspense>} />
        <Route path='/fileADeductionAppeal' element={<Suspense fallback={<Loading />}><FileADeductionAppealPage /></Suspense>} />
        <Route path={ROUTE_APPEAL_MANAGEMENT} element={<Suspense fallback={<Loading />}><SearchAppealsPage /></Suspense>} />
        <Route path={ROUTE_AO_APPEAL_MANAGEMENT} element={<Suspense fallback={<Loading />}><SearchTenAppealsPage /></Suspense>} />
        <Route path='/createHearingSchedule' element={<Suspense fallback={<Loading />}><CreateHearingSchedulePage /></Suspense>} />
        <Route path='/reports' element={<Suspense fallback={<Loading />}><ReportsPage /></Suspense>} />
        <Route path='/bulkjudgments' element={<Suspense fallback={<Loading />}><BulkJudgmentsPage /></Suspense>} />
        <Route path='/accountInfo' element={<Suspense fallback={<Loading />}><AccountInfoPage /></Suspense>} />
      </Route>
      <Route path='/appeal' element={<FullLayout />}>
        <Route path=':appealId' element={<Suspense fallback={<Loading />}><AppealDetailPage /></Suspense>} />
      </Route>
      {/*<Route path='/appealCalendar' element={<Suspense fallback={<Loading />}><AppealCalendarPage /></Suspense>} />*/}
      {/*<Route path='/appealSubmissionConfirmation' element={<Suspense fallback={<Loading />}><AppealsSubmissionConfirmationPage /></Suspense>} />*/}
      {/*<Route path='/viewJudgments' element={<Suspense fallback={<Loading />}><ViewJudgmentsPage /></Suspense>} />*/}
      {/*<Route path='/viewMetrics' element={<Suspense fallback={<Loading />}><MetricsPage /></Suspense>} />*/}
      {/*<Route path='/netValueAtRisk' element={<Suspense fallback={<Loading />}><NetValueAtRiskPage /></Suspense>} />*/}
      {/*<Route path='/appealsByMunicipality' element={<Suspense fallback={<Loading />}><AppealsByMunicipalityPage /></Suspense>} />*/}
      {/*<Route path='/appealsSummary' element={<Suspense fallback={<Loading />}><AppealsSummaryPage /></Suspense>} />*/}
      {/*<Route path='/settings' element={<Suspense fallback={<Loading />}><SettingsPage /></Suspense>} />*/}
      {/*<Route path='/userAdmin' element={<Suspense fallback={<Loading />}><UserAdminPage /></Suspense>} />*/}
    </Routes>
  );
}

export default UserRoutes;
