import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom'
import { setLayoutState } from '../../../ducks/app'
import classNames from 'classnames'

const  LayoutState = () => {
  const location = useLocation();
  const layoutState = useSelector(state => state.app.layoutState);
  const dispatch = useDispatch();

  const getURLLayoutParams = () => {
    const urlSearchParams = new URLSearchParams(location.search.replace('?', ''));
    const urlLayoutParams = {};
    for (const [key, value] of urlSearchParams.entries()) {
      urlLayoutParams[key] = value;
    }
    return urlLayoutParams;
  }

  function bootstrapLayoutSettings() {
    const urlParams = getURLLayoutParams();
    const storageParams = JSON.parse(window.localStorage.getItem('app.layoutState'));
    if (storageParams) {
      delete storageParams.settingsOpened;
    }
    const mergedParams = Object.assign({}, storageParams, urlParams);
    const booleanMergedParams = JSON.parse(
      JSON.stringify(mergedParams),
      (key, value) => (value === 'true' ? true : value === 'false' ? false : value),
    );
    dispatch(setLayoutState({ ...booleanMergedParams }));
  }

  useEffect(bootstrapLayoutSettings, []);
  useEffect(updateBodyClass, [layoutState]);

  function updateBodyClass() {
    document.body.className = classNames(layoutState);
  }

  return null;
}

export default LayoutState;
