import aws_exports from "../../../aws-exports";
import {fetchAuthSession, getCurrentUser} from "aws-amplify/auth";

const poolData = {UserPoolId: aws_exports.aws_user_pools_id, ClientId: aws_exports.aws_user_pools_web_client_id};

export const getCurrentUserAndGroups = async () => {
  try {
    const cognitoUser = await getCurrentUser();
    if (!cognitoUser) {
      return null;
    }
    let userGroups = [];
    let user = null;
    try {
      const session = await fetchAuthSession();
      const {idToken} = session.tokens ?? {};
      if (idToken && idToken.payload["cognito:groups"]) {
        userGroups = idToken.payload["cognito:groups"];
      }
      user = {
        username: cognitoUser.username,
        email: idToken.payload.email,
        phoneNumber: idToken.payload.phone_number?.substring(2)
      }
    } catch (err) {
      console.log("fetchAuthSession error:", err);
    }
    return {cognitoUser: user, userGroups};
  } catch (err) {
    //console.log("getCurrentUser error:", err);
    return null;
  }
}

export const getJWTToken = async () => {
    // let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    // const cognitoUser = userPool.getCurrentUser();
    // if (!cognitoUser) {
    //     return null;
    // }
    // const session = cognitoUser.getSession( (err, session) => {
    //     if (err) {
    //         console.log(err);
    //     }
    //     return session;
    // } );
    const session = await fetchAuthSession();
    if (session){
        return session.tokens?.idToken?.toString();
    };
    return null;
}
