// @flow
import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { selectMenuData } from '../../../ducks/app';
import { useSelector } from "react-redux";
import './style.scss';

const Breadcrumb = ({name}) => {
  const [breadcrumb, setBreadcrumb] = useState([]);
  const url = useLocation().pathname;
  const menuData = useSelector(selectMenuData);

  useEffect(() => {
    getBreadcrumb(menuData);
  }, [url, menuData, name]);

  const getPath = (data, url, parents = []) => {
    let items = data.reduce(
      (result, entry) => {
        if (result.length) {
          return result;
        } else if (entry.url === url) {
          return [entry].concat(parents);
        } else if (entry.children) {
          let nested = getPath(entry.children, url, [entry].concat(parents))
          return nested.length ? nested : result;
        }
        return result;
      },
      [],
    )
    //return items.length > 0 ? items : false
    return items;
  }

  const getBreadcrumb = (items) => {
    let [activeMenuItem, ...path] = getPath(items, url);
    if (activeMenuItem && path.length) {
      setBreadcrumb( path.reverse().map((item, index) => {
        if (index === path.length - 1) {
          return (
            <span key={item.key}>
              <span className="breadcrumbBar__arrow text-muted" />
              <span className="text-muted">{item.title}</span>
              <span className="breadcrumbBar__arrow text-muted" />
              <strong>
                <span className="breadcrumbBar__active">{activeMenuItem.title}</span>
              </strong>
            </span>
          )
        } else {
          return (
            <span key={item.key}>
              <span className="breadcrumbBar__arrow text-muted" />
              <span className="text-muted">{item.title}</span>
            </span>
          )
        }
      }));
    } else {
      const linkName = activeMenuItem ? activeMenuItem.title : name;
      setBreadcrumb(
        <span>
          <span className="breadcrumbBar__arrow text-muted" />
          <strong>
            <span className="breadcrumbBar__active">{linkName}</span>
          </strong>
        </span>
      );
    }
  }

  return (
    <div className="breadcrumbBar">
      <div className="breadcrumbBar__path">
        <Link to={`/searchAppeals`} className="text-muted">
          Home
        </Link>
        {breadcrumb}
      </div>
    </div>
  )
}

export default Breadcrumb;
