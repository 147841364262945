import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {selectCounty} from "./newAppeal";
import {RestStatus} from "./appealDetail";

const initialState = {
  modIVRows: null,
  modIV10Rows: null,
}

export const downloadModIV = createAsyncThunk(
  'modIV/downloadModIV',
  async (municipality, {getState}) => {
    const state = getState();
    const county = selectCounty(state);
    const response = await fetch('https://s3.amazonaws.com/mynjappealstorage/ModIV/' + county.key + '/MODIV'+ municipality.key + '.txt', {cache: 'no-cache'});
    return response.text();
  }
);
export const downloadModIV10 = createAsyncThunk(
  'modIV/downloadModIV10',
  async (municipality, {getState}) => {
    const state = getState();
    const county = selectCounty(state);
    const response = await fetch('https://s3.amazonaws.com/mynjappealstorage/ModIV/' + county.key + '/MODIV'+ municipality.key + '-10.txt', {cache: 'no-cache'});
    return response.text();
  }
);

export const modIVSlice = createSlice({
    name: 'modIV',
    initialState: initialState,
    reducers: {
      clearModIVData: (state, action) => {
        state.modIVRows = [];
        state.modIVLoadStatus = null;
        state.modIVLoadError = '';
        state.modIV10Rows = [];
        state.modIV10LoadStatus = null;
        state.modIV10LoadError = '';
      }
    },
    extraReducers: (builder) => {
      builder
        .addCase(downloadModIV.pending, (state, action) => {
          state.modIVLoadStatus = RestStatus.LOADING;
          state.modIVLoadError = '';
        })
        .addCase(downloadModIV.fulfilled, (state, action) => {
          state.modIVLoadStatus = RestStatus.LOADED;
          state.modIVRows = action.payload.split(/\r?\n/);
          state.modIVLoadError = '';
        })
        .addCase(downloadModIV.rejected, (state, action) => {
          state.modIVLoadStatus = RestStatus.LOAD_FAILED;
          state.modIVLoadError = action.error.message;
        })
        .addCase(downloadModIV10.pending, (state, action) => {
          state.modIV10LoadStatus = RestStatus.LOADING;
          state.modIV10LoadError = '';
        })
        .addCase(downloadModIV10.fulfilled, (state, action) => {
          state.modIV10LoadStatus = RestStatus.LOADED;
          state.modIV10LoadError = '';
          const tenholder = [];

          action.payload.split(/\r?\n/).forEach(e => {
            const prefixhold = e.substring(0, 35);
            const texthold = e.substring(35).match(/.{1,87}/g) || [];

            texthold.forEach(function (e) {
              if (e.trim().length > 0 && !e.match(/\s*E$/)) {
                tenholder.push(prefixhold + e);
              }
            });
          });

          state.modIV10Rows = tenholder.filter(e => e.replace(/ /g, '').length > 0);
        })
        .addCase(downloadModIV10.rejected, (state, action) => {
          state.modIV10LoadStatus = RestStatus.LOAD_FAILED;
          state.modIV10LoadError = action.error.message;
        })
    }
  });

export const {clearModIVData} = modIVSlice.actions;
export const selectModIVRows = state => state.modIV.modIVRows;
export const selectModIVLoadStatus = state => state.modIV.modIVLoadStatus;
export const selectModIVLoadError = state => state.modIV.modIVLoadError;
export const selectModIV10Rows = state => state.modIV.modIV10Rows;
export const selectModIV10LoadStatus = state => state.modIV.modIV10LoadStatus;
export const selectModIV10LoadError = state => state.modIV.modIV10LoadError;


export default modIVSlice.reducer;