import countyAppealSchedules from './countyAppealSchedules.json';
import countyData from './countyData.json';
import dayjs from "dayjs";
const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("America/New_York");
var isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);

export const SCHEDULE_EARLY = 0;
export const SCHEDULE_ACTIVE = 1;
export const SCHEDULE_LATE = 2;
const arrayIsEmpty = a => a === null || a === undefined || a.length === 0;

const scheduleStatus = ({start, end}) => {
  //if (process.env.REACT_APP_STAGE === 'BVT') return true;
  const now = dayjs();
  const currentYear = dayjs().format('YYYY');
  let startDate = dayjs(`${currentYear}-${start}`, 'YYYY-MMM-DD HH:mm');
  let endDate = dayjs(`${currentYear}-${end}`, 'YYYY-MMM-DD HH:mm');
  if (endDate.isBefore(startDate)) {
    if (now.isBefore(endDate)) {
      startDate = startDate.subtract(1,'year');
    } else {
      endDate = endDate.add(1,'year');
    }
  }
  return now.isBetween(startDate, endDate, "second", "[)") ? SCHEDULE_ACTIVE
    : now.isBefore(startDate) ? SCHEDULE_EARLY : SCHEDULE_LATE;
}
const countyIsActive = (county, municipalities, schedule) => {
  let active = false;
  const munisInCountyWithOverrides = schedule.municipalityOverrides
    ? municipalities.filter(m => schedule.municipalityOverrides[m.key]) : [];
  const aMuniOverrideIsActive = munisInCountyWithOverrides.reduce(
    (anyActive, muniSchedule) => anyActive || (scheduleStatus(muniSchedule) === SCHEDULE_ACTIVE), false);
  return aMuniOverrideIsActive || (scheduleStatus(schedule) === SCHEDULE_ACTIVE);
}

export const getActiveCountiesForAppealType = (appealType, county, municipalities) => {
  const countyPool = !county ? countyData.counties : [county];
  const muniPool = municipalities || [];
  return countyPool.filter(c => {
    const countySchedule = countyAppealSchedules[c.key];
    return countySchedule && countySchedule[appealType] && countyIsActive(c, muniPool, countySchedule[appealType]);
  })
}

export const getAppealSchedule = (appealType, municipalityKey) => countyAppealSchedules[municipalityKey.substring(0,2)][appealType];

export const isMunicipalityActiveForAppealType = (appealType, municipalityKey) => {
  const countyKey = municipalityKey.substring(0,2);
  const countySchedule = countyAppealSchedules[countyKey][appealType];
  const municipalityOverride = countySchedule.municipalityOverrides[municipalityKey];
  return municipalityOverride ? scheduleStatus(municipalityOverride) : scheduleStatus(countySchedule);
}

import {AppealType} from "./constants";

export const getActiveAppealYear = (county, appealType) => {
  const now = new Date();
  const currentYear = now.getFullYear();
  if (county.key === '03') {
    if (appealType === AppealType.ADD_OMIT) {
      if (now.getMonth() < 9) {
        return currentYear - 1;
      } else {
        return currentYear;
      }
    } else {
      if (now.getMonth() > 10) {
        return currentYear + 1;
      } else {
        return currentYear;
      }
    }
  }
  //   //TODO fix this to be April 1 if not a weekend
  //   if ((now.getMonth() === 3 && now.getDate() > 3) || now.getMonth() > 3) {
  //     return currentYear + 1;
  //   }
  //   return currentYear;
  // } else {
  //   if (appealType === AppealType.ADD_OMIT) {
  //     if (now.getMonth() < 9) {
  //       return currentYear - 1;
  //     } else {
  //       return currentYear;
  //     }
  //   }
  //   if (now.getMonth() < 1) {
  //     return currentYear - 1;
  //   }
  //
  // }
  return currentYear;
}

export const getJudgmentSinceDate = (county, appealType) => dayjs('2023-10-01T04:00:00.000Z');