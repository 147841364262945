import app from './app';
import newAppeal from './newAppeal';
import creditCard from "./creditCard";
import appealDetail from "./appealDetail";
import modIV from './modIV';
import appealList from "./appealList";

const reducer = {
  app,
  newAppeal,
  creditCard,
  appealDetail,
  modIV,
  appealList,
};

export default reducer;
