import React from "react";

export const AppealStatus = {
  "WORK_IN_PROGRESS": "Work In Progress",
  "STIPULATED": "Stipulated",
  "READY_FOR_BOARD": "Ready For Board",
  "JUDGMENT_ISSUED": "Judgment Issued",
  "WITHDRAWN": "Withdrawn",
};

export const AppealType = {
  "VALUE_CLASS": "Value/class",
  "DEDUCTION": "Deduction",
  "ADD_OMIT": "Addition/omission",
};

export const PaymentMethod = {
  NONE: "None",
  CASH: "Cash",
  CHECK: "Check",
  MONEY_ORDER: "Money Order",
  CARD_PRESENT: "Card Present",
};
export const PAYMENT_METHOD_ONLINE_CARD = "Online Card";

export const EntryMethod = {
  ONLINE: "ONLINE",
  PAPER: "PAPER",
}

export const AdditionRecordType = {
    "ADDED": {listType:"3", label: "Added"},
    "OMITTED": {listType:"2", label: "Omitted"},
    "OMITTED_ADDED": {listType:"4", label: "Omitted/Added"},
}

export const DeductionAction = {
  "GRANT": "Grant",
  "REVOKE": "Revoke",
}

export const getAppealType = appeal => {
  const prefix = appeal.appealNumber.split("@")[0];
  if (prefix === '10') {
    return AppealType.ADD_OMIT;
  }
  if (appeal.deduction &&
    (appeal.deduction.abatement || appeal.deduction.disabledVeteranExemption ||
      appeal.deduction.seniorCitizenOrDisabledPerson || appeal.deduction.veteranDeduction)) {
    return AppealType.DEDUCTION;
  }
  return AppealType.VALUE_CLASS;
}

export const StipulationStatus = {
  "PROPOSED": "PROPOSED",
  "APPELLANT_APPROVED": "APPELLANTAPPROVED",
  "APPELLANT_REJECTED": "APPELLANTREJECTED",
  "SOLICITOR_APPROVED": "SOLICITORAPPROVED",
  "SOLICITOR_REJECTED": "SOLICITORREJECTED",
}

export const JudgmentResultCodes = [
  { "key": "1A", "label": "1A Assessment Revised - True Value Exceeds 100%" },
  { "key": "1B", "label": "1B Assessment Revised - Assessment outside range (N.J.S.A. 54:3-22)" },
  { "key": "1C", "label": "1C Assessment Revised - Material Depreciation (N.J.S.A. 54:4-35.1)" },
  { "key": "1D", "label": "1D Assessment Revised - Personal Property" },
  { "key": "1E", "label": "1E Assessment Revised - Other" },
  { "key": "2A", "label": "2A Assessment Affirmed - Assessment within range (N.J.S.A. 54:3-22)" },
  { "key": "2B", "label": "2B Assessment Affirmed - Presumption of correctness not overturned" },
  { "key": "2C", "label": "2C Assessment Affirmed - Personal Property" },
  { "key": "3", "label": "3 Stipulated" },
  { "key": "4A", "label": "4A Freeze Act - Granted" },
  { "key": "4B", "label": "4B Freeze Act - Denied" },
  { "key": "5A", "label": "5A Dismissed With Prejudice - Non-appearance (lack of prosecution)" },
  { "key": "5B", "label": "5B Dismissed With Prejudice - No evidence provided (lack of prosecution)" },
  { "key": "5C", "label": "5C Dismissed With Prejudice - Taxes/municipal charges not paid (N.J.S.A. 54:3-27)" },
  { "key": "5D", "label": "5D Dismissed With Prejudice - Failure to respond to income request (N.J.S.A. 54:3-34)" },
  { "key": "5E", "label": "5E Dismissed With Prejudice - Appeal not timely filed (N.J.S.A. 54:3-21)" },
  { "key": "5F", "label": "5F Dismissed With Prejudice - Other" },
  { "key": "6A", "label": "6A Dismissed Without Prejudice -  Tax Court pending for Year" },
  { "key": "6B", "label": "6B Dismissed Without Prejudice - Hearing Waived" },
  { "key": "7", "label": "7 Withdrawn" },
  { "key": "8A", "label": "8A Property Tax Deduction - Granted" },
  { "key": "8B", "label": "8B Property Tax Deduction - Denied" },
  { "key": "9A", "label": "9A Farmland Assessment Granted - Qualification approved per application" },
  { "key": "9B", "label": "9B Farmland Assessment Granted - Qualified acres changed" },
  { "key": "9C", "label": "9C Farmland Assessment Granted - Qualified value changed" },
  { "key": "9D", "label": "9D Farmland Assessment Granted - B. & C. above" },
  { "key": "10", "label": "10 Farmland Assessment Denied" },
  { "key": "11A", "label": "11A Veteran Deduction - Granted" },
  { "key": "11B", "label": "11B Veteran Deduction - Denied" },
  { "key": "12A", "label": "12A 100% Disabled Veteran - Granted" },
  { "key": "12B", "label": "12B 100% Disabled Veteran - Denied" },
  { "key": "13A", "label": "13A Exempt Property/Abatement - Granted" },
  { "key": "13B", "label": "13B Exempt Property/Abatement - Denied" },
  { "key": "13C", "label": "13C Exempt Property/Abatement - Exempt/Abatement amount changed" },
  { "key": "14A", "label": "14A Added Assessment Affirmed - As filed by assessor" },
  { "key": "14B", "label": "14B Added Assessment Affirmed - Prorated months changed" },
  { "key": "14C", "label": "14C Added Assessment Affirmed - Valuation changed" },
  { "key": "14D", "label": "14D Added Assessment Affirmed - B. & C. above" },
  { "key": "15", "label": "15 Added Assessment Removed" },
  { "key": "16A", "label": "16A Omitted Assessment Affirmed - As filed by assessor" },
  { "key": "16B", "label": "16B Omitted Assessment Affirmed - Prorated months changed" },
  { "key": "16C", "label": "16C Omitted Assessment Affirmed - Valuation changed" },
  { "key": "16D", "label": "16D Omitted Assessment Affirmed - B. & C. above" },
  { "key": "17", "label": "17 Omitted Assessment Removed" },
];

export const APPEAL_STATUS_CHANGE_EVENT = 'Appeal Status Changed To ';