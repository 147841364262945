import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
  getChargeAuthorizationURL,
  getMerchantAuthentication
} from "../pages/DefaultPages/common/merchantAuthenticationByCounty";
import axios from "axios";

export const ChargeRequestStatus = {
  NONE: 'none',
  PENDING: 'pending',
  APPROVED: 'approved',
  DECLINED: 'declined',
  FAILED: 'failed',
};

const initialCardState = {
  cardNumber: null,
  expiration: null,
  cvc: null,
  cardHolderName: null,
};

const initialState = {
  card: initialCardState,
  chargeStatus: ChargeRequestStatus.NONE
}

export const wasChargeApproved = response => response.messages.resultCode === "Ok" &&
  response.transactionResponse.authCode && response.transactionResponse.messages[0].code === "1";


export const chargeCreditCard = createAsyncThunk(
  "creditCard/charge",
  async ({countyKey, amount}, {getState, dispatch}) => {
    const merchantAuthentication = getMerchantAuthentication(countyKey);
    const state = getState();
    const cardNumber = selectCreditCardNumber(state);
    const payment = {
      creditCard: {
        cardNumber,
        expirationDate: selectCreditCardExpiration(state),
        cardCode: selectCreditCardCvc(state)
      }
    };
    dispatch(clearCreditCard());
    const response = await axios
      .post(getChargeAuthorizationURL(countyKey), {
        createTransactionRequest: {
          merchantAuthentication: merchantAuthentication,
          refId: Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          transactionRequest: {
            transactionType: "authCaptureTransaction",
            amount: amount,
            payment,
            transactionSettings: {
              setting: {
                settingName: "duplicateWindow",
                settingValue: 0
              }
            }
          }
        }
      });
    return response.data;
  }
);

export const creditCardSlice = createSlice({
  name: 'creditCard',
  initialState: initialState,
  reducers: {
    setCreditCardNumber: (state, action) => {
      state.card.cardNumber = action.payload;
    },
    setCreditCardExpiration: (state, action) => {
      state.card.expiration = action.payload;
    },
    setCreditCardCvc: (state, action) => {
      state.card.cvc = action.payload;
    },
    setCreditCardHolderName: (state, action) => {
      state.card.cardHolderName = action.payload;
    },
    clearCreditCard: (state) => {
      state.card = initialCardState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(chargeCreditCard.pending, (state, action) => {
        state.chargeStatus = ChargeRequestStatus.PENDING;
        state.chargeError = '';
      })
      .addCase(chargeCreditCard.fulfilled, (state, action) => {
        state.chargeStatus = wasChargeApproved(action.payload) ? ChargeRequestStatus.APPROVED : ChargeRequestStatus.DECLINED;
        state.chargeResponse = action.payload;
      })
      .addCase(chargeCreditCard.rejected, (state, action) => {
        state.chargeError = action.error.message;
        state.chargeStatus = ChargeRequestStatus.FAILED;
      });
  }
});
export const selectCreditCard = state => state.creditCard.card;
export const selectCreditCardNumber = state => state.creditCard.card.cardNumber;
export const selectCreditCardExpiration = state => state.creditCard.card.expiration;
export const selectCreditCardCvc = state => state.creditCard.card.cvc;
export const selectCreditCardHolderName = state => state.creditCard.card.cardHolderName;
export const selectChargeStatus = state => state.creditCard.chargeStatus;
export const selectChargeError = state => state.creditCard.chargeError;


export const {setCreditCardNumber, setCreditCardExpiration, setCreditCardCvc, setCreditCardHolderName, clearCreditCard} = creditCardSlice.actions;

export default creditCardSlice.reducer;

