
const merchantAuthentications = {
  "01":{
    "name": process.env.REACT_APP_MERCHANT_NAME_ATLANTIC,
    "transactionKey": process.env.REACT_APP_TRANSACTION_KEY_ATLANTIC
  },
  "03":{
    "name": process.env.REACT_APP_MERCHANT_NAME_BURLINGTON,
    "transactionKey": process.env.REACT_APP_TRANSACTION_KEY_BURLINGTON
  },
  "04":{
    "name": process.env.REACT_APP_MERCHANT_NAME_CAMDEN,
    "transactionKey": process.env.REACT_APP_TRANSACTION_KEY_CAMDEN
  }
};

const chargeAuthorizationURLs = {
  "01": process.env.REACT_APP_AUTHORIZE_URL_ATLANTIC,
  "03": process.env.REACT_APP_AUTHORIZE_URL_BURLINGTON,
  "04": process.env.REACT_APP_AUTHORIZE_URL_CAMDEN
};

export const getMerchantAuthentication = countyKey => merchantAuthentications[countyKey];

export const getChargeAuthorizationURL = countyKey => chargeAuthorizationURLs[countyKey];

