import React from 'react';
import {Button} from "antd";
import './style.scss'
import {MenuOutlined} from "@ant-design/icons";
import {useDispatch} from "react-redux";
import {toggleMobileMenu} from "../../../ducks/app";

const Greeting = ({user, signOut, isMobile}) => {
  const dispatch = useDispatch();
  const toggleMenu = () => dispatch(toggleMobileMenu());
  return (
    <div className="greeting ">
      {isMobile &&
      <Button icon={<MenuOutlined />} onClick={toggleMenu} />
      }
      <span style={{flexGrow: 2, textAlign: "right"}}>Hello {user.username}</span>
      <Button
        id="signOut"
        onClick={signOut}
        size="large"
        type="primary"
      >Sign Out</Button>
    </div>
  );
}

export default Greeting;