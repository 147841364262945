import React from 'react'
import {useSelector} from 'react-redux'
import { UserOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Avatar, Badge } from 'antd'
import {selectUserState} from "../../../../ducks/app";

const mapStateToProps = (state, props) => ({
  userState: state.app.userState,
})

const ProfileMenu = () => {
  const userState = useSelector(selectUserState);
  const menu = (
    <Menu selectable={false}>
      <Menu.Item>
        <div className="rfq__widget__system-status__item">
          <strong>Hello, {userState.role}</strong>
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a href="/#/notifications">
          <i className="topbar__dropdownMenuIcon icmn-bell" /> Notifications
        </a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a href="/#/settings">
          <i className="topbar__dropdownMenuIcon icmn-user" /> Edit Profile
        </a>
      </Menu.Item>
      <Menu.Divider />
    </Menu>
  )
  return (
    <div className="topbar__dropdown d-inline-block">
      <Dropdown
        overlay={menu}
        trigger={['click']}
        placement="bottomRight"
        onVisibleChange={this.addCount}
      >
        <a className="ant-dropdown-link" href="/">
          <Badge>
            <Avatar className="topbar__avatar" shape="square" size="large" icon={<UserOutlined />} />
          </Badge>
        </a>
      </Dropdown>
    </div>
  );
}

export default ProfileMenu;
