//import 'rc-drawer/assets/index.css'
import React from 'react'
import {Drawer} from 'antd'
import MenuLeft from './MenuLeft'
import MenuTop from './MenuTop'
import ProfileMenu from './MenuRight'
import { useDispatch, useSelector } from 'react-redux'
import { setLayoutState } from '../../../ducks/app'
import './style.scss'

const mapStateToProps = ({ app }, props) => ({
  open: app.layoutState.menuMobileOpened,
  isMenuTop: app.layoutState.isMenuTop,
})

const AppMenu = (props) => {
  const { isMobile } = props;
  const dispatch = useDispatch();
  const isMenuTop = useSelector(state => state.app.layoutState.isMenuTop);
  const open = useSelector(state => state.app.layoutState.menuMobileOpened);
  const toggleOpen = () => {
    dispatch(setLayoutState({ menuMobileOpened: !open }));
  }

  return isMobile ? (
    <Drawer
      placement="left"
      open={open}
      onClose={toggleOpen}
    >
      <MenuLeft {...props} />
      {/*<ProfileMenu />*/}
    </Drawer>
  ) : isMenuTop ? (
    <MenuTop {...props} />
  ) : (
    <MenuLeft {...props} />
  );
}

export default AppMenu
