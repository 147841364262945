import {ConfigProvider} from "antd";
import enUS from "antd/locale/en_US";
import {Helmet} from "react-helmet";
import Layout from "../Layout";
import React from "react";

export const FullLayout = () => {
  return (
    <ConfigProvider
        locale={enUS}
        theme={{
          components: {
            Form: {
              itemMarginBottom: 12,
            },
          },
        }}    >
      <div id="mynjappeal">
        <Helmet titleTemplate="MyNJAppeals - %s" />
        <Layout />
      </div>
    </ConfigProvider>
  )
}