// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile =  {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:1407b206-c5bd-4d1b-a33f-cef20e66e883",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_tGm96OlYm",
    "aws_user_pools_web_client_id": "7v00f1m2n6rb9n7ncuhrb26qr4",
    "aws_user_files_s3_bucket": "mynjappealstorage",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
